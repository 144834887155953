<template>
  <div class="homepage">
    <Hero v-if="content.hero && showHero" :content="content.hero" />

    <FocusRibbon v-if="content.focus && !showHero" :content="content.focus" />

    <UiProducts
      v-if="content.solutions_related"
      :content="content.solutions_related"
    />

    <SolutionsV2 :content="content.solutions_grid" />

    <DuoCombo v-if="content.combo" :content="content.combo" />

    <KeyfactsText
      v-if="content.keyfacts_texts"
      :content="content.keyfacts_texts"
    />

    <GridNews v-if="content.news_grid?.cards" :content="content.news_grid" />

    <CtaRibbon v-if="content.ctaRibbon" :content="content.ctaRibbon" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import CtaRibbon from '@/components/cta/Ribbon.vue'
import DuoCombo from '@/components/duo/Combo.vue'
import FocusRibbon from '@/components/focus/Ribbon.vue'
import GridNews from '@/components/grid/News.vue'
import SolutionsV2 from '@/components/grid/SolutionsV2.vue'
import Hero from '@/components/hero/Homepage.vue'
import KeyfactsText from '@/components/keyfacts/Text.vue'
import UiProducts from '@/components/ui/Products.vue'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const showHero = ref(props.content?.hero?.show_hero)
</script>
