<template>
  <div ref="rootRef" class="hero">
    <div ref="list" class="list-slider">
      <div
        v-for="(item, index) in content.items"
        :key="item.title"
        class="list-item flickity-cell"
        :class="[
          `bg-${item.background_color || 'cobalt'}`,
          { 'text-background': item.text_background },
        ]"
      >
        <div class="wrapper">
          <div class="list-item-container">
            <component
              :is="index === 0 ? 'h1' : 'h2'"
              ref="title"
              class="h1 hero-title"
            >
              <span v-html="item.title"></span>
            </component>

            <div
              v-if="item.links?.length"
              ref="buttons"
              class="item-links mt-l"
            >
              <template
                v-for="(link, indexLink) in item.links"
                :key="indexLink"
              >
                <Action
                  v-if="link.link"
                  :content="{
                    ...link.link,
                    modifiers:
                      indexLink === 0
                        ? [
                            'btn',
                            'cta',
                            item.background_color === 'red-gradient' &&
                            !item.text_background
                              ? 'white'
                              : '',
                          ]
                        : [
                            'btn',
                            'bordered',
                            item.background_color === 'red-gradient' &&
                            !item.text_background
                              ? 'white'
                              : 'black',
                          ],
                  }"
                  class="focus__links__item"
                />
              </template>
            </div>
          </div>

          <img
            v-if="item.background_picture"
            ref="picture"
            v-src="item.background_picture"
            class="list-item-picture"
            :alt="item.background_picture.alt"
            size="(min-width: 960px) 80vw, 100vw"
          />
        </div>
      </div>
    </div>

    <div class="hero-navigation">
      <div data-staggered-left class="flickity__button">
        <button
          class="button button--next"
          aria-label="Suivant"
          @click="flickity.next()"
        >
          <SvgSprite aria-hidden="true" symbol="ui-arrow-left" size="24" />
        </button>

        <button
          class="button button--prev"
          aria-label="Précédent"
          @click="flickity.previous()"
        >
          <SvgSprite aria-hidden="true" symbol="ui-arrow-left" size="24" />
        </button>
      </div>
      <ul ref="navigation" class="nav-list" :data-size="content.items.length">
        <li
          v-for="(item, index) in content.items"
          :key="index"
          class="nav-list-el"
          :class="[
            {
              before:
                indexSelected - 1 === index &&
                !isDrag &&
                content.items.length > 3,
            },
          ]"
          @click="flickity.select(index)"
        >
          <div class="nav-list-el-top">
            <img
              v-if="item.navigation.picture"
              id="card"
              v-src="item.navigation.picture"
              :class="`bg-${item.background_color}`"
              class="nav-list-el-picture"
              :alt="item.navigation.picture.alt"
              :sets="['320', '480', '640']"
              size="(min-width: 960px) 50vw, 100vw"
            />
          </div>
          <span class="h4 bold">{{ item.navigation.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import { useResize } from '@/utils/aware'

import type { PropType } from 'vue'
import type { Picture, Uri } from '@/types'

const indexSelected = ref(0)

interface HeroHomepage {
  items: {
    title: string // Wysiwyg
    text_background?: boolean
    links: {
      link: Uri
    }[]
    background_picture: Picture
    background_color: string
    navigation: {
      picture: Picture
      title: string
    }
  }[]
}

const rootRef = ref()
const title = ref()
const picture = ref()

const list = ref()
const buttons = ref()
const navigation = ref()
const isMobile = ref(false)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let flickity: any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let flickityNav: any

onMounted(async () => {
  if (!rootRef.value) {
    return
  }

  await initFlickity()

  useResize(onWindowResize)

  updateStatus()
})

const isDrag = ref(false)

const initFlickity = async () => {
  const Flickity = await import(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    /* webpackChunkName: 'vendor-flickity' */ 'flickity'
  ).then(module => module.default)

  // eslint-disable-next-line prefer-const
  flickity = new Flickity(list.value as HTMLElement, {
    pageDots: false,
    prevNextButtons: false,
    cellAlign: 'left',
    // autoPlay: 10000,
    draggable: true,
    wrapAround: true,
    pauseAutoPlayOnHover: true,
    dragThreshold: 100,
  })

  flickityNav = new Flickity(navigation.value as HTMLElement, {
    pageDots: false,
    prevNextButtons: false,
    cellAlign: 'left',
    draggable: true,
    freeScroll: false,
    wrapAround: true,
    dragThreshold: 100,
  })

  flickity.on('select', updateStatus)
  flickityNav.on('dragStart', draggingStart)
  flickityNav.on('dragEnd', draggingEnd)

  useResize(() => {
    flickityNav && flickityNav.resize()
  })
}

const updateStatus = () => {
  indexSelected.value = flickity.selectedIndex + 1
  flickityNav.select(indexSelected.value)
}

const draggingEnd = () => {
  isDrag.value = false
  flickity.select(flickityNav.selectedIndex - 1)
}
const draggingStart = () => {
  isDrag.value = true
}

defineProps({
  content: {
    type: Object as PropType<HeroHomepage>,
    required: true,
  },
})

const onWindowResize = () => {
  isMobile.value = window.innerWidth < 1024

  initFlickity()
}
</script>

<style lang="scss" scoped>
$bp: 's';

.hero {
  position: relative;
  overflow: hidden;
}

.flickity-cell {
  position: absolute;
  width: 100%;
}

.list-slider {
  outline: none;

  @include mq($until: $bp) {
    padding-bottom: 20rem;
  }
}

.list-item {
  overflow: hidden;
  height: 70rem;
  background: var(--cg-accent);

  &[class*='light-'] {
    background: var(--c-accent);
  }

  .wrapper {
    position: relative;

    @include mq($bp) {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &.bg-red-gradient:not(&.text-background) {
    color: $c-white;
  }

  .list-item-picture {
    pointer-events: none;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;

    @include mq($bp) {
      width: 50%;
    }
  }

  @include mq($until: $bp) {
    height: 60rem;
    padding-top: 4rem;
  }
}

.bg-red-gradient {
  /* stylelint-disable-next-line declaration-no-important */
  background: linear-gradient(149deg, #f72717, #ff007d) !important;
}

.list-item-container {
  position: relative;
  z-index: 20;

  .text-background & {
    &::before {
      position: absolute;
      z-index: -1;
      top: -15%;
      right: -20%;
      display: block;
      width: 2000%;
      content: '';
      height: 130%;
      background-color: $c-white;
      border-radius: 0 20rem 20rem 0;

      @include mq($until: $bp) {
        right: -5%;
      }
    }
  }

  @include mq($until: $bp) {
    .bg-red-gradient & {
      width: 95%;
    }
  }

  @include mq($bp) {
    width: 50%;
  }
}

.flickity__item {
  pointer-events: none;
  position: absolute;
  width: 90%;
  margin-right: 0.8rem;

  @include mq($bp) {
    width: 45rem;
  }
}

.item-links {
  display: flex;
  gap: 1rem;
}

// Navigation
.nav {
  position: absolute;
  pointer-events: none;
  z-index: 10;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  @include mq($bp) {
    width: 75%;
  }
}

.nav-list {
  @extend %list-nostyle;

  margin-left: 5rem;
  gap: 1rem;
  outline: none;

  :deep(.flickity-slider) {
    height: 20rem;

    @include mq($bp) {
      height: 30rem;
    }
  }

  :deep(.flickity-viewport) {
    width: 100%;
    padding-left: 0.3rem;

    @include mq($until: $bp) {
      /* stylelint-disable-next-line declaration-no-important */
      height: inherit !important;
      padding-top: 2rem;
      padding-bottom: 3rem;
      padding-left: 1rem;
      outline: none;
    }
  }
}

.nav-list-el-picture {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  background: var(--c-accent-20);
  pointer-events: none;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
}

.nav-list-el {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  min-width: 140px;
  max-width: 140px;
  height: 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-bottom: 1.8rem;
  gap: 1rem;
  text-align: center;
  background-color: $c-white;
  border-radius: 2rem;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  .h4 {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
    line-height: 1.1;
    text-align: left;
  }

  &:hover {
    .nav-list-el-picture {
      transform: scale(1.1);
    }
  }

  &.before {
    opacity: 0;
  }

  @include mq($until: $bp) {
    box-shadow: 0 2.353px 10.529px 0 rgba(0, 0, 0, 10%);
  }

  @include mq($bp) {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
  }
}

.nav-list-el-top {
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  width: 4.4rem;
  height: 4.4rem;
  background-color: $c-white;
  border: none;
  border-radius: 100%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 10%);
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $c-main;

    svg {
      fill: $c-white;
    }
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &[class*='-next'] {
    svg {
      transform: rotate(180deg);
    }
  }
}

.hero-navigation {
  position: absolute;
  bottom: 2rem;
  left: col(1);
  width: 100%;
  margin-left: auto;

  .flickity__button {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    @include mq($until: $bp) {
      top: 50%;
      transform: translateY(-50%);
    }

    @include mq($bp) {
      bottom: 0;
    }
  }

  ul {
    overflow: hidden;
  }

  @include mq($bp) {
    right: 0;
    width: 30%;
  }
}
</style>
